/* eslint no-unused-vars: 'off' */
import { store } from '@/store';

// orbit's strategies
import { ClientError, NetworkError } from '@orbit/jsonapi';
import { RequestStrategy, SyncStrategy } from '@orbit/coordinator';

const printError = (e, term, ops) => {
  try {
    store.notify.fail(store.translate(e.data.errors.detail));
    console.log(`${term} | ${ops} | ${e.message} | ${e.data.errors.detail}`);
  } catch {
    store.notify.warn(store.translate(term));
    console.log(`${term} | ${ops} | ${e.message}`);
  }
};

const updateFailAction = (e, requestQueue, backupQueue) => {
  console.log('updateFailAction');
  if (!(e instanceof ClientError) && requestQueue.current) {
    if (requestQueue.current.type === 'query') {
      printError(e, 'NETWORK_ERROR', 'QUERY IGNORE');
    } else {
      backupQueue.push(requestQueue.current);
      printError(e, 'NETWORK_ERROR', 'QUEUED');
    }
  } else {
    printError(e, 'REQUEST_ERROR', 'SKIPPED');
  }
  requestQueue.skip();
};

// Update the remote server whenever memory is updated
export const remoteUpdate = new RequestStrategy({
  name: 'remote-update-request',
  source: 'memory',
  on: 'beforeUpdate',
  target: 'remote',
  // pre Orbit v0.17 action was 'push'
  action: 'update',
  blocking: true,
  catch(e) {
    // errors caught by remoteUpdateFail
  },
});

export const remoteUpdateFail = new RequestStrategy({
  name: 'remote-update-fail',
  source: 'remote',
  // pre Orbit v0.17 'updateFail' was 'pushFail'
  on: 'updateFail',
  action(transform, e) {
    updateFailAction(e, this.source.requestQueue, this.coordinator._queue);
  },
});

export const remoteQueryFail = new RequestStrategy({
  name: 'remote-query-fail',
  source: 'remote',
  on: 'queryFail',
  action(transform, e) {
    console.log('remoteQueryFail');
    // pre Orbit v0.17 the check below was for NetworkError
    if (e instanceof TypeError) {
      this.source.requestQueue.skip();
    }
  },
});

export const remotePullFail = new RequestStrategy({
  name: 'remote-pull-fail',
  source: 'remote',
  on: 'pullFail',
  action(transform, e) {
    console.log('remotePullFail');
    // pre Orbit v0.17 the check below was for NetworkError
    if (e instanceof TypeError) {
      this.source.requestQueue.skip();
    }
  },
});

// Sync all changes received from the remote server to the memory
export const remoteMemorySync = new SyncStrategy({
  name: 'remote-memory-sync',
  source: 'remote',
  target: 'memory',
  blocking: true,
  // action(transform, e) {
  //   console.log('remoteMemorySync');
  //   this.target.sync(transform).catch(() => {
  //     console.log('remoteMemorySync: error when syncing');
  //   });
  // },
  catch(e) {
    console.log('remoteMemorySync: error');
  },
});

// Sync all changes received from memory to the remote server
export const memoryRemoteSync = new SyncStrategy({
  name: 'memory-remote-sync',
  source: 'memory',
  target: 'remote',
  blocking: true,
  // action(transform, e) {
  //   console.log('memoryRemoteSync');
  //   this.source.sync(transform).catch(() => {
  //     console.log('memoryRemoteSync: error when syncing');
  //   });
  // },
  catch(e) {
    console.log('memoryRemoteSync: error');
  },
});

// Back up data to IndexedDB
export const memoryBackupSync = new SyncStrategy({
  name: 'memory-backup-sync',
  source: 'memory',
  target: 'backup',
  blocking: false,
  // action(transform, e) {
  //   console.log('memoryBackupSync');
  //   this.target.sync(transform).catch(() => {
  //     console.log('memoryBackupSync: error when syncing');
  //   });
  // },
  catch(e) {
    console.log('memoryBackupSync: error');
  },
});

// Export Strategies

// Update the remote server whenever memory is updated
export const exportRemotePush = new RequestStrategy({
  name: 'export-remote-update-request',
  source: 'export-memory',
  on: 'beforeUpdate',
  target: 'export-remote',
  blocking: true,
  async action(transform, e) {
    await this.target['push'](transform);
    store.notify.success(store.translate('PROJECTS_EXPORTED'));
  },
  catch(e) {
    printError(e, 'NETWORK_ERROR', 'QUEUED');
    this.coordinator._queue.push(this.source.requestQueue.current);
    this.target.requestQueue.skip();
  },
});

// TODO: push errors handled in exportRemotePush
// Do we need exportRemotePushFail?
export const exportRemotePushFail = new RequestStrategy({
  name: 'export-remote-update-fail',
  source: 'export-remote',
  // pre Orbit v0.17 'updateFail' was 'pushFail'
  on: 'updateFail',
  action(transform, e) {
    updateFailAction(e, this.source.requestQueue, this.coordinator._queue);
  },
});

// Sync all changes received from memory to the remote server
export const exportMemoryRemoteSync = new SyncStrategy({
  name: 'export-memory-remote-sync',
  source: 'export-memory',
  target: 'export-remote',
  blocking: true,
  // action(transform, e) {
  //   console.log('memoryRemoteSync');
  //   this.source.sync(transform).catch(() => {
  //     console.log('memoryRemoteSync: error when syncing');
  //   });
  // },
  catch(e) {
    console.log('memoryRemoteSync: error');
  },
});

// Back up data to IndexedDB
export const exportMemoryBackupSync = new SyncStrategy({
  name: 'export-memory-backup-sync',
  source: 'export-memory',
  target: 'export-backup',
  blocking: false,
  // action(transform, e) {
  //   console.log('exportMemoryBackupSync');
  //   this.target.sync(transform).catch(() => {
  //     console.log('exportMemoryBackupSync: error when syncing');
  //   });
  // },
  catch(e) {
    console.log('exportMemoryBackupSync: error');
  },
});
