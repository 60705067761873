import { createApp } from 'vue';

import { IonicVue } from '@ionic/vue';
import * as allIcons from 'ionicons/icons';
import '@ionic/core/css/ionic.bundle.css';

import App from './App.vue';
import i18n from './i18n';
import router from './router';

import { store } from '@/store';
import calculatorService from '@/services/calculator.service';
import orbitService from '@/services/orbit.service';
import userService from '@/services/user.service';
import './registerServiceWorker';

if (process.env.NODE_ENV === 'development') {
  // console.clear();
}

const app = createApp(App).use(IonicVue).use(router).use(i18n);
app.mixin({
  methods: {
    ionIcon(name) {
      return allIcons[name];
    },
  },
});

// i18n is required in calculatorService
calculatorService.i18n = i18n.global;

app.provide('calculatorService', calculatorService);
app.provide('orbitService', orbitService);
app.provide('userService', userService);
app.provide('store', store);

app.config.globalProperties.$calculatorService = calculatorService;
app.config.globalProperties.$orbitService = orbitService;
app.config.globalProperties.$userService = userService;
app.config.globalProperties.$filters = {
  dateFormatted(value) {
    return value ? value.split('+')[0] : 'N/A';
  },
  truncate(text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
  },
};

router.isReady().then(() => {
  app.mount('#app');
});

// on start-up, get locale & user and navigate
store.setLocale(userService.getLocale());
if (userService.getUser().id) {
  router.push({ name: 'project.list' });
} else {
  router.push({ name: 'login' });
}
