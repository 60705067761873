/*
 CalculatorServiceSingleton functions:
 - interpret the range values into strings for each of the ranges
 - lookup the values in the RoH json file and return RoH and aRoH
 - format the RoH result
 - is used by each of the Target tab screens: Vehicle/Human/Property
*/
import CryptoJS from 'crypto-js';

import { sleep } from './helpers';

class CalculatorServiceSingleton {
  CRYPTO_KEY = process.env.VUE_APP_CRYPTO_KEY;
  // fxRates will change each year
  fxRates = {
    'en-gb': 1.0, // base rate of 1:1 for en-gb English (UK)
    'en-au': 1.8, // English (Australia)
    'en-ca': 1.6, // English (Canada)
    'en-ie': 1.1, // English (Eire)
    'cs-cz': 27.0, // Czech Republic
    'da-dk': 8.4, // Denmark
    'fr-ca': 1.7, // French (Canada)
    'fr-fr': 1.1, // Euro - France
    'de-de': 1.1, // Euro - Germany
    'es-es': 1.1, // Euro - Spain
    'en-hk': 9.4, // Hong Kong (Traditional Chinese)
    'en-nz': 1.9, // New Zealand
    'pl-pl': 5.3, // Poland
    'en-sg': 1.6, // Singapore
    'en-za': 20.0, // South Africa
    'sv-se': 13.0, // Sweden
    'en-us': 1.2, // English (US)
    // "ja-jp" : 160.00, // Japan
    'zh-hk': 9.4, // Hong Kong (Traditional Chinese)
    'pt-pt': 1.1, // Portugal
    'it-it': 1.1, // Italy
  };
  lookupURLEnc = '/data/roh-enc.txt';
  rohLookup = {};

  constructor() {
    this.loadLookup()
      .then(() => Object.freeze(this))
      .catch(error => console.log(error));
  }

  async isReady() {
    while (Object.keys(this.rohLookup).length === 0) {
      await sleep(500);
    }
    return true;
  }

  async loadLookup() {
    this.rohLookup = await fetch(this.lookupURLEnc)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then(
        response => {
          const decrypted = CryptoJS.AES.decrypt(response, this.CRYPTO_KEY);
          try {
            return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
          } catch (error) {
            throw Error(error);
          }
        },
        error => {
          throw Error(error);
        }
      );
  }

  lookupRoH(t, s, f) {
    // - searches rohLookup using the range value inputs
    // - returns roh (=capped aRoH)
    // TODO: Is this the best search method to use?
    for (let i = 0; i < this.rohLookup.length; i++) {
      if (
        this.rohLookup[i].tr === t &&
        this.rohLookup[i].sz === s &&
        this.rohLookup[i].pf === f
      ) {
        return this.rohLookup[i].roh;
      }
    }
    return '';
  }

  lookupActualRoH(t, s, f) {
    // - searches rohLookup using the range value inputs
    // - returns aRoH (uncapped)
    // TODO:  Is this the best search method to use?
    for (let i = 0; i < this.rohLookup.length; i++) {
      if (
        this.rohLookup[i].tr === t &&
        this.rohLookup[i].sz === s &&
        this.rohLookup[i].pf === f
      ) {
        return this.rohLookup[i].aroh;
      }
    }
    return '';
  }

  getBandColour(aroh) {
    // - uses aRoH and Multiple Targets to calculate the RiskBand<n> class
    // - colours the result box
    // - RiskBand1-4 defined in style.css
    if (isNaN(aroh) || aroh === 0) {
      return 'RiskBandError';
    } else if (aroh <= 1000) {
      return 'RiskBand1'; // RED
    } else if (aroh <= 10000) {
      return 'RiskBand2'; // AMBER
    } else if (aroh <= 1000000) {
      return 'RiskBand3'; // YELLOW
    } else if (aroh > 1000000) {
      return 'RiskBand4'; // GREEN
    } else {
      return '';
    }
  }

  sigFigure(value, figs) {
    if (!value || value === 0) {
      return value;
    }
    const EPSILON = 0.00001;
    const neg = value < 0;
    if (neg) {
      value = -value;
    }
    const m10 = Math.log(value) / Math.log(10);
    const scale = Math.pow(10, Math.floor(m10) - (figs - 1));
    const valBS = Math.round(value / scale) * scale;
    const valEQ = Math.round(value / scale + EPSILON) * scale;
    if (valEQ != valBS) {
      value = valEQ;
    } else {
      value = valBS;
    }
    if (neg) {
      value = -value;
    }
    return value;
  }

  strRightPos(haystack, needle, offset) {
    // discuss at: http://phpjs.org/functions/strRightPos/
    // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // bugfixed by: Onno Marsman
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    let i = -1;

    if (offset) {
      i = (haystack + '').slice(offset).lastIndexOf(needle); // strRightPos' offset indicates starting point of range till end,

      // while lastIndexOf's optional 2nd argument indicates ending point of range from the beginning
      if (i !== -1) {
        i += offset;
      }
    } else {
      i = (haystack + '').lastIndexOf(needle);
    }

    return i >= 0 ? i : false;
  }

  substrReplace(str, replace, start, length) {
    // discuss at: http://phpjs.org/functions/substrReplace/
    // original by: Brett Zamir (http://brett-zamir.me)
    if (start < 0) {
      // start position in str
      start = start + str.length;
    }

    length = length !== undefined ? length : str.length;

    if (length < 0) {
      length = length + str.length - start;
    }

    return (
      str.slice(0, start) +
      replace.substr(0, length) +
      replace.slice(length) +
      str.slice(start + length)
    );
  }

  strRightReplace(needle, replace, haystack) {
    let pos = this.strRightPos(haystack, needle, false);
    if (pos) {
      haystack = this.substrReplace(haystack, replace, pos);
    }

    return haystack;
  }

  abbreviateRoH(value) {
    // - converts RoH and aRoH into a readable format 1,000,000 -> 1M
    /*
    let result = value.toString();
    result = this.strRightReplace('000000000000', 'T', result);
    result = this.strRightReplace('000000000', 'B', result);
    result = this.strRightReplace('000000', 'M', result);
    result = this.strRightReplace('000', 'K', result);
    */
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(value);
  }

  getTargetRange(mode, targetRange) {
    switch (mode) {
      case 'vehicle':
        return this.getVehicleRange(targetRange);
      case 'human':
        return [
          this.getHumanRange(targetRange, 1),
          this.getHumanRange(targetRange, 2),
        ];
      case 'property':
        return this.getPropertyRange(targetRange);
    }
  }

  getVehicleRange(tr) {
    // - returns the different vehicle frequencies for the Vehicle Range selected
    // - returned result will be assigned to targetRangeText in controller
    const result = [];
    const kph = this.i18n.t('KPH');
    const mph = this.i18n.t('MPH');
    const speeds = {
      1: ' @ 120' + kph + ' (75' + mph + ')',
      2: ' @ 110' + kph + ' (68' + mph + ')',
      3: ' @ 100' + kph + ' (62' + mph + ')',
      4: ' @ 90' + kph + ' (56' + mph + ')',
      5: ' @ 80' + kph + ' (50' + mph + ')',
      6: ' @ 70' + kph + ' (43' + mph + ')',
      7: ' @ 60' + kph + ' (37' + mph + ')',
      8: ' @ 50' + kph + ' (32' + mph + ')',
    };
    const noneText = this.i18n.t('NO_VEHICLES');
    switch (tr) {
      case 1:
        result.push('24 000-2 500' + speeds[1]);
        result.push('26 000-2 700' + speeds[2]);
        result.push('28 000-2 900' + speeds[3]);
        result.push('31 000-3 200' + speeds[4]);
        result.push('32 000-3 300' + speeds[5]);
        result.push('36 000-3 700' + speeds[6]);
        result.push('42 000-4 300' + speeds[7]);
        result.push('47 000-4 800' + speeds[8]);
        return result;
      case 2:
        result.push('2 400-250' + speeds[1]);
        result.push('2 600-270' + speeds[2]);
        result.push('2 800-290' + speeds[3]);
        result.push('3 100-320' + speeds[4]);
        result.push('3 200-330' + speeds[5]);
        result.push('3 600-370' + speeds[6]);
        result.push('4 200-430' + speeds[7]);
        result.push('4 700-480' + speeds[8]);
        return result;
      case 3:
        result.push('240-25' + speeds[1]);
        result.push('260-27' + speeds[2]);
        result.push('280-29' + speeds[3]);
        result.push('310-32' + speeds[4]);
        result.push('320-33' + speeds[5]);
        result.push('360-37' + speeds[6]);
        result.push('420-43' + speeds[7]);
        result.push('470-48' + speeds[8]);
        return result;
      case 4:
        result.push('24-3' + speeds[1]);
        result.push('26-4' + speeds[2]);
        result.push('28-4' + speeds[3]);
        result.push('31-4' + speeds[4]);
        result.push('32-4' + speeds[5]);
        result.push('36-5' + speeds[6]);
        result.push('42-5' + speeds[7]);
        result.push('47-6' + speeds[8]);
        return result;
      case 5:
        result.push('2-1' + speeds[1]);
        result.push('3-1' + speeds[2]);
        result.push('3-1' + speeds[3]);
        result.push('3-1' + speeds[4]);
        result.push('3-1' + speeds[5]);
        result.push('4-1' + speeds[6]);
        result.push('4-1' + speeds[7]);
        result.push('5-1' + speeds[8]);
        return result;
      case 6:
        result.push(noneText);
        return result;
      default:
        return [''];
    }
  }

  getHumanRange(hr, ver) {
    // - returns different occupancy period/no of pedestrian for Human range selected
    // - returned result will be assigned to targetRangeText in controller
    const constantText = this.i18n.t('HUMAN_CONSTANT');
    const dayText = this.i18n.t('HUMAN_DAY');
    const hoursText = this.i18n.t('HUMAN_HOURS');
    const hourText = this.i18n.t('HUMAN_HOUR');
    const minText = this.i18n.t('HUMAN_MIN');
    const weekText = this.i18n.t('HUMAN_WEEK');
    const monthText = this.i18n.t('HUMAN_MONTH');
    const yearText = this.i18n.t('HUMAN_YEAR');
    if (ver === 1) {
      switch (hr) {
        case 1:
          return constantText + '-2.5 ' + hoursText + '/' + dayText;
        case 2:
          return (
            '2.4 ' +
            hoursText +
            '/' +
            dayText +
            '-15 ' +
            minText +
            '/' +
            dayText
          );
        case 3:
          return (
            '14 ' + minText + '/' + dayText + '-2 ' + minText + '/' + dayText
          );
        case 4:
          return (
            '1 ' + minText + '/' + dayText + '-2 ' + minText + '/' + weekText
          );
        case 5:
          return (
            '1 ' + minText + '/' + weekText + '-1 ' + minText + '/' + monthText
          );
        case 6:
          return (
            '<1 ' +
            minText +
            '/' +
            monthText +
            '-0.5 ' +
            minText +
            '/' +
            yearText
          );
        default:
          return '';
      }
    } else if (ver === 2) {
      switch (hr) {
        case 1:
          return '720/' + hourText + '-73/' + hourText;
        case 2:
          return '72/' + hourText + '-8/' + hourText;
        case 3:
          return '7/' + hourText + '-2/' + hourText;
        case 4:
          return '1/' + hourText + '-3/' + dayText;
        case 5:
          return '2/' + dayText + '-2/' + weekText;
        case 6:
          return '1/' + weekText + '-6/' + yearText;
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  getPropertyRange(pr) {
    // - returns different property value ranges for Property range selected
    // - returned result will be assigned to targetRangeText in controller
    const rate = this.fxRates[this.i18n.locale];
    const toCurrencyLocale = value => {
      value = value * rate;
      // TODO: this was removed:
      // toPrecision returns a string but i18n.n expects a number
      // value = value.toPrecision(Math.log(value) / Math.log(10) > 1 ? 2 : 1);
      return this.i18n.n(value, 'currency');
    };
    switch (pr) {
      case 1:
        return [toCurrencyLocale(2000000) + ' -> ' + toCurrencyLocale(200000)];
      case 2:
        return [toCurrencyLocale(200000) + ' -> ' + toCurrencyLocale(20000)];
      case 3:
        return [toCurrencyLocale(20000) + ' -> ' + toCurrencyLocale(2000)];
      case 4:
        return [toCurrencyLocale(2000) + ' -> ' + toCurrencyLocale(200)];
      case 5:
        return [toCurrencyLocale(200) + ' -> ' + toCurrencyLocale(20)];
      case 6:
        return [toCurrencyLocale(20) + ' -> ' + toCurrencyLocale(2)];
      default:
        return [''];
    }
  }

  getSizeRange(size) {
    // - returns different Size values for Size range selected
    // - returned result will be assigned to sizeRangeText in controller
    // - ?? it is not called from PropertyCtrl as this does not use Size
    if (size >= 1 && size <= 4) {
      const sizes = {
        1: '>450mm DIA.',
        2: '450mm - 260mm DIA.',
        3: '250mm - 110mm DIA.',
        4: '100mm - 25mm DIA.',
      };
      return sizes[size];
    } else {
      return '';
    }
  }

  getPofRange(pf) {
    // - PoF = Probability of Failure
    // - returns different PoF ranges for PoF range selected
    // - returned result will be assigned to pofRangeText in controller
    if (pf >= 1 && pf <= 7) {
      let pofs = {
        1: '1/1 -> 1/10',
        2: '1/10 -> 1/100',
        3: '1/100 -> 1/1K',
        4: '1/1K -> 1/10K',
        5: '1/10K -> 1/100K',
        6: '1/100K -> 1/1M',
        7: '1/1M -> 1/10M',
      };
      return pofs[pf];
    } else {
      return '';
    }
  }

  adjustWithMt(aroh, roh, mt) {
    // adjust aroh with MT
    const denominator = aroh / mt;
    const zeros = Math.floor(denominator).toString().length - 1;
    const multiplier = 10 ** zeros;
    const dBm = denominator / multiplier;
    const remainder = dBm - Math.floor(dBm);
    const floor = Math.floor(denominator / multiplier) * multiplier;
    const round = Math.ceil(denominator / multiplier) * multiplier;
    const adjusted = dBm > 1 && remainder > 0.5 ? round : floor;
    return { aroh: adjusted, roh: aroh > 1000000 ? 1000000 : adjusted };
  }

  updateRiskOfHarm(target, qtra) {
    // handle property exceptions
    if (target === 'property') {
      qtra.sizeRange = 99;
      qtra.reduceMass = '100%';
    }

    let aroh = this.lookupActualRoH(
      qtra.targetRange,
      qtra.sizeRange,
      qtra.pofRange
    );
    let roh = this.lookupRoH(qtra.targetRange, qtra.sizeRange, qtra.pofRange);

    if (qtra.reduceMass === '50%' || qtra.reduceMass === '25%') {
      if (qtra.reduceMass === '50%') {
        aroh = this.sigFigure(aroh * 2, 1);
        roh = this.sigFigure(roh * 2, 1);
      } else if (qtra.reduceMass === '25%') {
        aroh = this.sigFigure(aroh * 4, 1);
        roh = this.sigFigure(roh * 4, 1);
      }
      if (aroh > 1000000) {
        roh = 1000000;
      }
    }

    const THRESHOLD = 1;
    const mt = qtra.multiTarget;
    const calcs = mt > 1 ? this.adjustWithMt(aroh, roh, mt) : { aroh, roh };
    let text = '';

    if (calcs.aroh <= 0) {
      text = this.i18n.t('SURVEY_ERROR_TEXT');
    } else if (qtra.capped) {
      // capped
      text += calcs.aroh > 1000000 ? '<1' : '1';
      if (mt > 1 && calcs.roh < THRESHOLD) {
        text += '(' + mt + 'T)';
      }
      text += '/';
      text += this.abbreviateRoH(calcs.roh < THRESHOLD ? roh : calcs.roh);
      if (mt > 1 && calcs.roh >= 1) {
        text += mt > 1 ? `(${mt}T)` : '';
      }
    } else {
      // NOT capped
      text += '1';
      if (mt > 1 && calcs.aroh < THRESHOLD) {
        text += '(' + mt + 'T)';
      }
      text += '/';
      text += this.abbreviateRoH(calcs.aroh < THRESHOLD ? aroh : calcs.aroh);
      if (mt > 1 && calcs.aroh >= 1) {
        text += mt > 1 ? `(${mt}T)` : '';
      }
    }

    const color = this.getBandColour(calcs.aroh);

    return { text, color };
  }
}

const instance = new CalculatorServiceSingleton();

export default instance;
