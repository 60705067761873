import { reactive, watch } from 'vue';

import { toastController } from '@ionic/vue';

export const state = reactive({
  busy: false,
  queue: [],
  toast: null,
});

const add = async (message, color, duration, dismiss) => {
  if (dismiss) {
    if (state.queue.length > 1) {
      state.queue = [];
    }
    state.queue.push({ message, color, duration });
    if (state.toast) await state.toast.dismiss();
  } else {
    state.queue.push({ message, color, duration });
  }
};

const process = async () => {
  if (state.busy || state.queue.length === 0) return;

  state.busy = true;

  const { message, color, duration } = state.queue[0];
  state.toast = await toastController.create({
    color,
    duration,
    message,
    mode: 'ios',
    cssClass: 'position-ion-toast',
    buttons: [{ side: 'end', text: 'x' }],
  });

  state.toast.present();
  await state.toast.onDidDismiss();
  state.queue.shift();

  state.busy = false;

  if (state.queue.length > 0) {
    process();
  }
};

watch(
  () => [...state.queue],
  () => process()
);

const fail = message => add(message, 'danger', 4000, true);
const log = message => add(message, 'medium', 3000, true);
const success = message => add(message, 'success', 3000, false);
const warn = message => add(message, 'warning', 4000, true);

export const notify = { fail, log, success, warn };
