// TODO: update import when vie-i18n warning is removed
// import { createI18n } from 'vue-i18n';
import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js';

// Currency references:
//   https://www.iban.com/currency-codes
//   https://codepen.io/SitePoint/pen/oNYVEKg
export const locales = [
  { id: 'cs-cz', currency: 'CZK', file: 'cs', label: 'Čeština' },
  { id: 'da-dk', currency: 'DKK', file: 'da', label: 'Dansk' },
  { id: 'de-de', currency: 'EUR', file: 'de', label: 'Deutsch' },
  { id: 'en-gb', currency: 'GBP', file: 'en', label: 'English (UK)' },
  { id: 'en-au', currency: 'AUD', file: 'en', label: 'English (Australia)' },
  { id: 'en-ca', currency: 'CAD', file: 'en', label: 'English (Canada)' },
  { id: 'en-ie', currency: 'EUR', file: 'en', label: 'English (Eire)' },
  { id: 'en-hk', currency: 'HKD', file: 'en', label: 'English (Hong Kong)' },
  { id: 'en-nz', currency: 'NZD', file: 'en', label: 'English (New Zealand)' },
  { id: 'en-sg', currency: 'SGD', file: 'en', label: 'English (Singapore)' },
  { id: 'en-us', currency: 'USD', file: 'en', label: 'English (US)' },
  { id: 'en-za', currency: 'ZAR', file: 'en', label: 'English (South Africa)' },
  { id: 'es-es', currency: 'EUR', file: 'es', label: 'Español' },
  { id: 'fr-ca', currency: 'CAD', file: 'fr', label: 'Français (Canada)' },
  { id: 'fr-fr', currency: 'EUR', file: 'fr', label: 'Français (France)' },
  { id: 'it-it', currency: 'EUR', file: 'it', label: 'Italiano' },
  // { id: 'ja-jp', currency: 'JPY', file: 'ja', label: '日本語' },
  { id: 'pl-pl', currency: 'PLN', file: 'pl', label: 'Polski' },
  { id: 'pt-pt', currency: 'EUR', file: 'pt', label: 'Português' },
  { id: 'sv-se', currency: 'SEK', file: 'sv', label: 'Svenska' },
  { id: 'zh-hk', currency: 'CNY', file: 'zh', label: '中文 (香港)' },
];

const numberFormats = {};
locales.forEach(x => {
  numberFormats[x.id] = {
    currency: {
      style: 'currency',
      currency: x.currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
    },
  };
});

const loadLocaleMessages = () => {
  const files = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  files.keys().forEach(translationFile => {
    const matched = translationFile.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const file = matched[1];
      const territories = locales.filter(x => x.file === file).map(x => x.id);
      for (let territory in territories) {
        // TODO: this is a temporary fix to use EN for all messages until
        // Mike supplies the translations
        // messages[territories[territory]] = files(translationFile);
        messages[territories[territory]] = files('./en.json');
      }
    }
  });
  return messages;
};

const fallbackLocale = locales.reduce((acc, value) => {
  return { ...acc, [value.id]: ['en!'] };
}, {});

const riskAssessmentTranslationMissing = (locale, key) => {
  if (key.includes('ASSESSMENT_PARTS') || key.includes('ASSESSMENT_TARGETS')) {
    const parts =
      messages[locale]['ASSESSMENT_PARTS'] &&
      messages[locale]['ASSESSMENT_PARTS'].length;
    const targets =
      messages[locale]['ASSESSMENT_TARGETS'] &&
      messages[locale]['ASSESSMENT_TARGETS'].length;
    if (key.includes(`[${parts}]`) || key.includes(`[${targets}]`)) {
      return true;
    }
  }
  return false;
};

const messages = loadLocaleMessages();

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-gb',
  fallbackLocale,
  messages,
  numberFormats,
  silentFallbackWarn: true,
  missing: (locale, key, vm) => {
    if (
      vm &&
      locale.includes('-') &&
      !riskAssessmentTranslationMissing(locale, key)
    ) {
      console.log(
        `[${locale}] ${key} missing in ${vm.type.name || vm.type.__name}`
      );
    }
  },
});
