import { createRouter, createWebHistory } from '@ionic/vue-router';
import userService from '@/services/user.service';
import orbitService from '@/services/orbit.service';
import Tabs from '../views/Tabs.vue';
import { store } from '@/store/index';

const loggedInGuard = (to, from, next) => {
  if (userService.getToken()) {
    next();
  } else {
    next({ name: 'login' });
  }
};

const routes = [
  {
    path: '/',
    redirect: '/projects',
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/tabs/',
    component: Tabs,
    beforeEnter: loggedInGuard,
    children: [
      {
        path: '',
        redirect: '/projects',
      },
      {
        path: '/projects',
        name: 'project.list',
        component: () =>
          import(
            /* webpackChunkName: "project.list" */ '../views/ProjectList.vue'
          ),
      },
      {
        path: '/projects/add',
        name: 'project.add',
        component: () =>
          import(
            /* webpackChunkName: "project.add" */ '../views/ProjectAdd.vue'
          ),
      },
      {
        path: '/projects/export',
        name: 'project.export',
        component: () =>
          import(
            /* webpackChunkName: "project.export" */ '../views/ExportProjects.vue'
          ),
      },
      {
        path: '/projects/:projectId',
        name: 'project.detail',
        component: () =>
          import(
            /* webpackChunkName: "project.detail" */ '../views/ProjectDetail.vue'
          ),
      },
      {
        path: '/projects/:projectId/edit',
        name: 'project.edit',
        component: () =>
          import(
            /* webpackChunkName: "project.edit" */ '../views/ProjectEdit.vue'
          ),
      },
      {
        path: '/projects/:projectId/add',
        name: 'item.add',
        component: () =>
          import(/* webpackChunkName: "project.add" */ '../views/ItemAdd.vue'),
      },
      {
        path: '/projects/:projectId/:itemId',
        name: 'item.detail',
        component: () =>
          import(
            /* webpackChunkName: "item.detail" */ '../views/ItemDetail.vue'
          ),
      },
      {
        path: '/projects/:projectId/:itemId/edit',
        name: 'item.edit',
        component: () =>
          import(/* webpackChunkName: "item.edit" */ '../views/ItemEdit.vue'),
      },
      {
        path: '/projects/:projectId/:itemId/add',
        name: 'survey.add',
        meta: { addNew: true },
        component: () =>
          import(
            /* webpackChunkName: "survey.add" */ '../views/SurveyDetail.vue'
          ),
      },
      {
        path: '/projects/:projectId/:itemId/:surveyId',
        name: 'survey.detail',
        meta: { addNew: false },
        component: () =>
          import(
            /* webpackChunkName: "survey.detail" */ '../views/SurveyDetail.vue'
          ),
      },
      {
        path: '/demo',
        name: 'demo',
        component: () =>
          import(/* webpackChunkName: "demo" */ '../views/Demo.vue'),
      },
      {
        path: '/account',
        name: 'account',
        component: () =>
          import(/* webpackChunkName: "account" */ '../views/Account.vue'),
      },
      {
        path: '/help',
        name: 'help',
        component: () =>
          import(/* webpackChunkName: "help" */ '../views/Help.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const unixTime = Math.floor(Date.now() / 1000);
  if (unixTime > userService.tokenExpiry && to.name !== 'login') {
    userService.clearUserData();
    await orbitService.clearUserData();
    store.notify.warn(store.translate('SESSION_EXPIRED'));
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
