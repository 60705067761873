import Coordinator from '@orbit/coordinator';
import IndexedDBSource from '@orbit/indexeddb';
import IndexedDBBucket from '@orbit/indexeddb-bucket';
import JSONAPISource from '@orbit/jsonapi';
import MemorySource from '@orbit/memory';
import { TaskQueue } from '@orbit/core';

import { store } from '@/store';

import { sleep } from './helpers';
import { keyMap, exportSchema, schema } from './orbit/schema';

import userService from '@/services/user.service';

import {
  remoteUpdate,
  remoteUpdateFail,
  remotePullFail,
  remoteQueryFail,
  remoteMemorySync,
  memoryRemoteSync,
  memoryBackupSync,
  exportRemotePush,
  exportRemotePushFail,
  exportMemoryRemoteSync,
  exportMemoryBackupSync,
} from './orbit/strategies';

class OrbitServiceSingleton {
  nameMaxLength = 30;
  pageSize = 10;
  ready = false;
  memory = new MemorySource({
    name: 'memory',
    schema,
    keyMap,
  });
  backup = new IndexedDBSource({
    schema,
    keyMap,
    name: 'backup',
    namespace: 'projects',
    defaultTransformOptions: { useBuffer: true },
  });
  remote = new JSONAPISource({
    schema,
    keyMap,
    name: 'remote',
    host: `${process.env.VUE_APP_SERVER}/api`,
  });

  exportMemory = new MemorySource({
    name: 'export-memory',
    schema: exportSchema,
  });
  exportBackup = new IndexedDBSource({
    schema: exportSchema,
    name: 'export-backup',
    namespace: 'exports',
    defaultTransformOptions: { useBuffer: true },
  });
  exportRemote = new JSONAPISource({
    schema: exportSchema,
    name: 'export-remote',
    host: `${process.env.VUE_APP_SERVER}/api`,
  });

  coordinator = null;
  exportCoordinator = null;
  queues = {};

  styles = [
    'padding: 10px 10px 10px 0px',
    'background: linear-gradient(#000000, #000000)',
    'color: white',
    'display: block',
  ].join(';');

  constructor() {
    this.setBearer();
    this.coordinator = new Coordinator({
      sources: [this.memory, this.remote, this.backup],
    });

    this.exportCoordinator = new Coordinator({
      sources: [this.exportMemory, this.exportRemote, this.exportBackup],
    });

    const projectsQueue = new TaskQueue(this.remote, {
      name: 'project-queue',
      bucket: new IndexedDBBucket({ namespace: 'project-bucket' }),
      autoProcess: false,
    });
    const exportsQueue = new TaskQueue(this.exportRemote, {
      name: 'export-queue',
      bucket: new IndexedDBBucket({ namespace: 'export-bucket' }),
      autoProcess: false,
    });
    this.queues = { projectsQueue, exportsQueue };

    // this is a hack to add the queue to the coordinator so
    // it can be accessed in the strategies
    this.coordinator._queue = projectsQueue;
    this.exportCoordinator._queue = exportsQueue;

    this.coordinator.addStrategy(remoteUpdateFail);
    this.coordinator.addStrategy(remotePullFail);
    this.coordinator.addStrategy(remoteQueryFail);
    this.coordinator.addStrategy(remoteUpdate);
    this.coordinator.addStrategy(remoteMemorySync);
    this.coordinator.addStrategy(memoryRemoteSync);
    this.coordinator.addStrategy(memoryBackupSync);

    this.exportCoordinator.addStrategy(exportRemotePushFail);
    this.exportCoordinator.addStrategy(exportRemotePush);
    this.exportCoordinator.addStrategy(exportMemoryRemoteSync);
    this.exportCoordinator.addStrategy(exportMemoryBackupSync);

    this.initialise()
      .then(() => this.loadDataFromMemory())
      .then(() => this.syncWithServer())
      .then(() => (this.ready = true))
      .then(() => Object.freeze(instance));
  }

  setBearer() {
    const token = userService.getToken();
    if (!token) return;
    this.remote.requestProcessor.defaultFetchSettings.headers[
      'Authorization'
    ] = `Bearer ${token}`;
    this.exportRemote.requestProcessor.defaultFetchSettings.headers[
      'Authorization'
    ] = `Bearer ${token}`;
  }

  async isReady() {
    while (!this.ready) {
      await sleep(500);
    }
    return true;
  }

  async clearUserData() {
    await this.memory.cache.reset();
    await this.exportMemory.cache.reset();

    await this.coordinator.deactivate();
    await this.exportCoordinator.deactivate();

    await window.indexedDB.deleteDatabase('projects');
    await window.indexedDB.deleteDatabase('exports');
    await window.indexedDB.deleteDatabase('project-bucket');
    await window.indexedDB.deleteDatabase('export-bucket');
    await window.indexedDB.deleteDatabase('remote-requests');
    await window.indexedDB.deleteDatabase('export-remote-requests');

    await this.coordinator.activate();
    await this.exportCoordinator.activate();
  }

  async initialise() {
    const allRecords = await this.backup.query(q => q.findRecords());
    await this.memory.sync(t => allRecords.map(r => t.addRecord(r)));
    await this.coordinator.activate();

    await this.exportCoordinator.activate();
  }

  async loadDataFromMemory() {
    await this.memory.query(q => q.findRecords('project'));
    await this.memory.query(q => q.findRecords('item'));
    await this.memory.query(q => q.findRecords('survey'));
  }

  async processQueues() {
    // this.queues.map(async queue => {
    Object.keys(this.queues).forEach(async key => {
      const queue = this.queues[key];
      if (queue.length > 0) {
        console.log(`${queue.name}:`, queue.length);
      }

      if (queue.current) {
        await queue.retry().catch(e => {
          if (e.response) {
            const status = e.response.status;
            if (status === 400 || status === 405) {
              console.log('error when retrying - skip [DATA LOSS?]');
              this.queue.skip();
            }
          }
        });
      }
    });
  }

  now() {
    const date = new Date();
    const isoDateTime = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
    return `${isoDateTime.split('.')[0]}+00:00`.replace('T', ' ');
  }

  subscribe(callback) {
    this.memory.on('transform', () => callback.call());
  }

  unsubscribe(callback) {
    this.memory.off('transform', () => callback.call());
  }

  async getEntities(type) {
    return this.memory.cache.query(q => q.findRecords(type).sort('-modified'));
  }

  async getEntity(type, uuid) {
    return await this.memory.cache.query(q =>
      q.findRecord({ type: type, id: uuid })
    );
  }

  async getRelatedEntities(entity, type) {
    return await this.memory.cache.query(q =>
      q.findRelatedRecords(entity, type).sort('-modified')
    );
  }

  async addProject(entry) {
    const now = this.now();
    const project = {
      type: 'project',
      attributes: {
        name: entry.attributes.name,
        deleted: false,
        created: now,
        modified: now,
      },
    };
    await this.memory.update(t => t.addRecord(project));
  }

  async addItem(entry, projectId) {
    const now = this.now();
    const item = {
      type: 'item',
      attributes: {
        name: entry.attributes.name,
        deleted: false,
        created: now,
        modified: now,
      },
      relationships: {
        project: {
          data: {
            type: 'project',
            id: projectId,
          },
        },
      },
    };
    await this.memory.update(t => t.addRecord(item));
  }

  async addSurvey(survey, itemId) {
    const now = this.now();
    const item = {
      type: 'survey',
      attributes: {
        target: survey.attributes.target,
        qtra: survey.attributes.qtra,
        info: survey.attributes.info,
        deleted: false,
        created: now,
        modified: now,
      },
      relationships: {
        item: {
          data: {
            type: 'item',
            id: itemId,
          },
        },
      },
    };
    await this.memory.update(t => t.addRecord(item));
  }

  async exportProjects(format, projects) {
    const item = {
      type: 'export',
      attributes: { created: this.now(), format, projects },
    };
    await this.exportMemory.update(t => t.addRecord(item));
  }

  async updateSurvey(survey) {
    await this.memory.update(t => t.updateRecord(survey));
  }

  async updateEntity(entity) {
    const record = JSON.parse(JSON.stringify(entity));
    record.attributes.modified = this.now();
    await this.memory.update(t => t.updateRecord(record));
  }

  async deleteEntity(entity) {
    entity.attributes.deleted = true;
    await this.memory.update(t => t.updateRecord(entity));
  }

  async queryServer(entity, offset = 0) {
    try {
      const entities = await this.remote.query(q =>
        q.findRecords(entity).page({ offset, limit: this.pageSize })
      );
      console.log(`remote ${entity}:`, entities.length);
      if (entities.length === this.pageSize) {
        return this.queryServer(entity, this.pageSize + offset);
      }
    } catch {
      /* errors also caught by remoteQueryFail */
      throw 'SYNC_FAILURE';
    }
  }

  async syncWithServer() {
    if (!userService.getToken()) return;
    try {
      await this.processQueues();
      await this.queryServer('project');
      await this.queryServer('item');
      await this.queryServer('survey');
      store.notify.success(store.translate('SYNC_SUCCESS'));
    } catch (e) {
      store.notify.warn(store.translate(e));
    }
  }

  log(msg) {
    console.log(`%c ${msg}`, this.styles);
  }
}

const instance = new OrbitServiceSingleton();

export default instance;
