<script setup>
import { onMounted } from 'vue';
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { useServiceWorker } from './composables/service-worker-details';
import UpdateBanner from './components/UpdateBanner.vue';

onMounted(() => useServiceWorker());
</script>

<template>
  <ion-app id="app-main">
    <UpdateBanner />
    <ion-router-outlet />
  </ion-app>
</template>

<style>
#app {
  font-family: '-apple-system', 'Helvetica Neue', 'Roboto', 'Segoe UI',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a {
  text-decoration: none;
}

.breadcrumb-arrow {
  position: relative;
  top: 4px;
  opacity: 0.75;
}

.ion-list-longer {
  margin-bottom: 80px;
}

.top-button {
  margin-top: 51px !important;
}

.middle-button {
  margin-left: 20px;
}

ion-toolbar {
  /*height: 44px;*/
  text-align: center;
}

ion-title {
  font-size: 17px;
}

ion-title.md.title-md {
  padding-bottom: 12px;
}

.position-ion-toast {
  top: -50px;
}
</style>
