import { alertController } from '@ionic/vue';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const createAlert = async ({
  headerText,
  subHeaderText,
  messageText,
  okText,
  cancelText,
  okCB,
  cancelCB,
  cssClass,
}) => {
  const buttons = [
    {
      text: okText,
      role: 'confirm',
      cssClass,
      handler: () => okCB(),
    },
  ];

  if (cancelText) {
    buttons.push({
      text: cancelText,
      role: 'cancel',
      handler: () => cancelCB(),
    });
  }

  const alert = await alertController.create({
    header: headerText,
    subHeader: subHeaderText,
    message: messageText,
    buttons,
    mode: 'ios',
  });
  await alert.present();
};

export const qtraError = (qtra, errorText) =>
  qtra.human.result.text === errorText ||
  qtra.vehicle.result.text === errorText ||
  qtra.property.result.text === errorText;

export const serverUrl = process.env.VUE_APP_SERVER;

export const siteName = serverUrl.includes('v3') ? 'Version 3' : 'Test';
