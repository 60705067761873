import { register } from 'register-service-worker';

// two hours
const refreshTime = 1000 * 60 * 60 * 2;

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: {
      scope: './',
    },
    registered(registration) {
      setInterval(() => {
        registration.update().catch(e => console.log(`ERROR: ${e.message}`));
      }, refreshTime);
    },
    updated(registration) {
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
