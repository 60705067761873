class UserServiceSingleton {
  cryptoKey = null;
  expiryDate = null;
  tokenExpiry = null;
  locale = null;
  token = null;
  user = {
    id: null,
    username: null,
  };
  appVersion = '3.2.0';
  calculatorVersion = '5.4.1';
  calculatorDate = '(02-Jan-2023)';

  constructor() {
    this.applyToken();
  }

  applyToken() {
    this.token = this.readToken();
    if (this.token) {
      this.parseToken();
    }
  }

  readToken() {
    return window.localStorage.getItem('token');
  }

  writeToken(token) {
    window.localStorage.setItem('token', token);
  }

  parseToken() {
    const parsed = JSON.parse(atob(this.token.split('.')[1]));
    this.cryptoKey = parsed.crypto_key;
    this.expiryDate = parsed.expiry_date;
    this.user.id = parsed.user_id;
    this.user.username = parsed.username;
    this.tokenExpiry = parsed.exp;
    this.setLocale(
      window.localStorage.getItem('locale') ||
        parsed.language ||
        process.env.VUE_APP_I18N_LOCALE
    );
  }

  clearUserData() {
    window.localStorage.removeItem('token');
    this.cryptoKey = null;
    this.expiryDate = null;
    this.locale = null;
    this.token = null;
    this.user.id = null;
    this.user.username = null;
  }

  getCryptoKey() {
    return this.cryptoKey;
  }

  getExpiryDate() {
    return this.expiryDate;
  }

  getLocale() {
    return (
      this.locale ||
      window.localStorage.getItem('locale') ||
      process.env.VUE_APP_I18N_LOCALE
    );
  }

  getToken() {
    return this.token;
  }

  getUser() {
    return this.user;
  }

  inProduction() {
    return process.env.NODE_ENV === 'production';
  }

  async login(username, password) {
    try {
      const url = `${process.env.VUE_APP_SERVER}/api/auth/calculator/`;
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch(url, { method: 'POST', body: formData });
      if (response.status === 200) {
        const data = await response.json();
        this.writeToken(data.token);
        this.applyToken();
        return { success: true };
      } else if (response.status === 400) {
        return { success: false, message: 'LOGIN_FAILED' };
      } else if (response.status === 401) {
        return { success: false, message: 'ACCOUNT_EXPIRED' };
      } else if (response.status === 403) {
        return { success: false, message: 'CHECK_YOUR_CREDENTIALS' };
      } else {
        console.error(response.statusText);
        return { success: false, message: 'SERVER_ERROR' };
      }
    } catch (error) {
      console.error(error.message);
      return { success: false, message: 'CHECK_YOUR_CONNECTION' };
    }
  }

  getAppVersion() {
    return this.appVersion;
  }

  getCalculatorVersion() {
    return this.calculatorVersion;
  }

  getCalculatorDate() {
    return this.calculatorDate;
  }

  setLocale(locale) {
    this.locale = locale;
    window.localStorage.setItem('locale', locale);
  }
}

const instance = new UserServiceSingleton();

// Object.freeze(instance);

export default instance;
