import { ref, onMounted } from 'vue';

export function useServiceWorker() {
  const updateExists = ref(false);
  const registration = ref(null);
  const refreshing = ref(false);

  async function updateAvailable(event) {
    registration.value = event.detail;
    updateExists.value = true;
  }

  function refreshApp() {
    updateExists.value = false;
    if (!registration.value || !registration.value.waiting) return;
    registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
  }

  onMounted(() => {
    if (process.env.NODE_ENV !== 'production') return;

    document.addEventListener('swUpdated', updateAvailable, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing.value) return;
      refreshing.value = true;
      window.location.reload();
    });
  });

  return {
    updateExists,
    refreshApp,
  };
}
