import { reactive, readonly, toRefs } from 'vue';

import i18n from '@/i18n.js';

import { notify, state as notifyState } from './notify.js';

import userService from '@/services/user.service';

const state = reactive({
  notify: { ...toRefs(notifyState) },
});

const setLocaleOnServer = async locale => {
  try {
    const url = `${process.env.VUE_APP_SERVER}/api/users/${userService.user.id}/`;

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${userService.token}`);

    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({ language: locale }),
    });

    if (response.status === 200) {
      notify.success('Success');
      userService.setLocale(locale);
      setLocale(locale);
    } else {
      console.error(response.statusText);
      notify.fail(i18n.global.t('SERVER_ERROR'));
    }
  } catch (err) {
    console.error(err.message);
    notify.fail(i18n.global.t('SERVER_ERROR'));
  }
};

const setLocale = locale => (i18n.global.locale = locale);

export const store = {
  state: readonly(state),
  notify: { ...notify },
  setLocale,
  setLocaleOnServer,
  translate: message => i18n.global.t(message),
};
