// orbit's data schema
import { RecordSchema, RecordKeyMap } from '@orbit/records';

export const keyMap = new RecordKeyMap();

export const exportSchema = new RecordSchema({
  models: {
    export: {
      attributes: {
        created: { type: 'string' },
        format: { type: 'string' },
        projects: { type: 'array' },
      },
    },
  },
});

export const schema = new RecordSchema({
  models: {
    project: {
      attributes: {
        name: { type: 'string' },
        deleted: { type: 'boolean' },
        created: { type: 'string' },
        modified: { type: 'string' },
      },
      relationships: {
        items: { kind: 'hasMany', type: 'item', inverse: 'project' },
      },
      keys: {
        remoteId: { type: 'string' },
      },
    },
    item: {
      attributes: {
        name: { type: 'string' },
        deleted: { type: 'boolean' },
        created: { type: 'string' },
        modified: { type: 'string' },
      },
      relationships: {
        project: { kind: 'hasOne', type: 'project', inverse: 'items' },
        surveys: { kind: 'hasMany', type: 'survey', inverse: 'item' },
      },
      keys: {
        remoteId: { type: 'string' },
      },
    },
    survey: {
      attributes: {
        target: { type: 'string' },
        qtra: { type: 'object' },
        info: { type: 'object' },
        deleted: { type: 'boolean' },
        created: { type: 'string' },
        modified: { type: 'string' },
      },
      relationships: {
        item: { kind: 'hasOne', type: 'item', inverse: 'surveys' },
      },
      keys: {
        remoteId: { type: 'string' },
      },
    },
  },
});

export const survey = Object.freeze({
  attributes: {
    target: 'vehicle',
    qtra: {
      vehicle: {
        targetRange: 4,
        multiTarget: 1,
        sizeRange: 1,
        reduceMass: '100%',
        pofRange: 4,
        capped: true,
        result: {
          text: null,
          color: null,
        },
      },
      human: {
        targetRange: 4,
        multiTarget: 1,
        sizeRange: 1,
        reduceMass: '100%',
        pofRange: 4,
        capped: true,
        result: {
          text: null,
          color: null,
        },
      },
      property: {
        targetRange: 4,
        multiTarget: 1,
        sizeRange: 1,
        reduceMass: '100%',
        pofRange: 4,
        capped: true,
        result: {
          text: null,
          color: null,
        },
      },
    },
    info: {
      treePart: '',
      treeTarget: '',
    },
  },
});
