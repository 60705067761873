<script setup>
import { IonCard } from '@ionic/vue';
import { useServiceWorker } from '../composables/service-worker-details';

const { updateExists, refreshApp } = useServiceWorker();
</script>

<template>
  <ion-card
    v-if="updateExists"
    class="update-notification"
    color="warning"
    @click="refreshApp"
  >
    {{ $t('UPDATE_AVAILABLE') }}
  </ion-card>
</template>

<style scoped>
.update-notification {
  position: fixed;
  padding: 2px 0;
  margin: 0;
  width: 100%;
  z-index: 100;
  border-radius: 0;
  text-align: center;
  font-size: 0.75rem;
  cursor: pointer;
}
</style>
